import styled from 'styled-components';

export default styled.section`
  background: #080809;
  .register-area {
    margin: 0 auto;
    padding-top: 90px;
    @media screen and (max-width: 768px) {
      padding-top: 80px;
      padding-bottom: 80px;
    }
    p.register-title {
      font-family: Manufaktur-Bold, sans-serif;
      font-style: normal;
      font-weight: 800;
      font-size: 44px;
      line-height: 96%;
      text-align: center;
      letter-spacing: 1px;
      color: #fc6a2c;
      text-transform: uppercase;
      @media screen and (max-width: 768px) {
        font-size: 40px;
      }
    }
    p.register-date {
      margin: 10px;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 200%;
      text-align: center;
      letter-spacing: 2px;
      text-transform: uppercase;
      color: #ffffff;
      span {
        color: #fc6a2c;
      }
    }
    p.register-address {
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 150%;
      text-align: center;
      color: #ffffff;
      max-width: 770px;
      margin: 10px auto;
    }

    .btn-primary {
      border-color: #f05e1f;

      &:focus {
        background: #f05e1f !important;
      }

      &:active {
        background: #f05e1f !important;
      }
    }

    .submit-button {
      &:focus {
        background: #f05e1f !important;
      }

      &:active {
        background: #f05e1f !important;
      }
    }

    button {
      margin-top: 40px;
      height: 42px;
      left: 302px;
      top: 172px;
      background: #fc6a2c;
      box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
      border-radius: 4px;
      text-transform: uppercase;

      @media screen and (max-width: 375px) {
        margin-top: 26px;
      }

      &:hover {
        background: #f05e1f !important;
      }

      &:active {
        background: #f05e1f !important;
      }
    }
  }
`;
