import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';

import ThrottleRegisterStyles from './ThrottleRegisterStyles';

const Register = ({ name, date, time, address }) => {
  return (
    <ThrottleRegisterStyles>
      <Container>
        <Row className="register-area">
          <Col sm={12} md={12} lg={12} className="text-center">
            {name === 'San Jose' ? (
              <p className="register-title">
                {name}, CA <span className="text-white">(POSTPONED)</span>
              </p>
            ) : (
              <p className="register-title">{name}, California</p>
            )}
            <p className="register-date">
              {date} {time && <span>&#8725;&#8725;&#8725;</span>} {time && time}
            </p>
            <p className="register-address">{address}</p>
            <a href="#registration-panel">
              <Button variant="primary">
                {name === 'San Jose' ? 'Stay Updated' : 'Register Now'}
              </Button>
            </a>
          </Col>
        </Row>
      </Container>
    </ThrottleRegisterStyles>
  );
};

export default Register;
