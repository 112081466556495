import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../../components/seo';
import Hero from './hero/hero';
import Register from './registerpanel/register';
import Card from './card/card';
import Agenda from './agenda/agenda';
import RegisterPanel from './registerpanel/registerpanel';
import Footer from './footer/footer';
import ThrottleStyles from './ThrottleStyles';

const SanJose = ({ data, location }) => {
  const {
    page: {
      seo,
    },
  } = data;
  return (
    <ThrottleStyles>
      <SEO seo={seo} />
      <Hero isSanJose child />
      <Register
        name="San Jose"
        date="THIS EVENT HAS BEEN POSTPONED. NEW DATE TO BE ANNOUNCED."
        time=""
        address="Due to changing COVID safety precautions in Santa Clara County, the San Jose Full Throttle event has been postponed.
        Subscribe to our newsletter to receive event updates and details as they become available."
      />
      <Card />
      <Agenda isSanJose />
      <RegisterPanel isSanJose location={location} child />
      <Footer child />
    </ThrottleStyles>
  );
};

// export const query = graphql`
//   query SanJoseQuery {
//     page: datoCmsWebsitePage(internalName: { eq: "Homepage" }) {
//       seo: seoMetaTags {
//         ...GatsbyDatoCmsSeoMetaTags
//       }
//     }
//   }
// `;

export default SanJose;
