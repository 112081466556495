import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Container, Row, Col } from 'react-bootstrap';

import Link from '../../../utils/Link';
import Image from '../../../utils/OptimizedImage';
import lightLogo from '../../../images/sm-light-logo.svg';
import ThrottleFooterStyles from './ThrottleFooterStyles';

const Footer = ({ child, isStatic = false }) => {
  const {
    data: { privacy },
  } = useStaticQuery(getData);
  return (
    <ThrottleFooterStyles
      className={child ? `arrow-bg ${isStatic && 'static-footer'}` : ''}
    >
      <Container>
        <Row>
          <Col md={12} lg={12} className="footer-desc-area text-center">
            <p className="short-title">PRESENTED BY</p>
            <Link to="/">
              <Image src={lightLogo} alt="Shopmonkey Logo" />
            </Link>
            <p className="long-desc">
              The all-in-one shop management software. Say goodbye to clunky
              shop management software and the chaos of paper and pen. Work
              faster and smarter with an all-in-one, cloud-based solution
              designed to help you grow your business.
            </p>
            <p className="short-desc">
              See why thousands of shops trust&nbsp;
              <Link to="/">
                <span>Shopmonkey</span>
              </Link>
            </p>
          </Col>
        </Row>
        <Row className="privacy-bar">
          <div className="copyright">
            <figure>
              <Image
                src={privacy.icon.image.url}
                alt={privacy.icon.internalName}
                title={privacy.icon.image.title}
              />
            </figure>
            {`© ${new Date().getFullYear()} ${privacy.copywrite}`}
          </div>
          <div className="privacy">
            {privacy.links.map(item => (
              <Link to={item.url} className="privacy-link" key={item.label}>
                {item.label}
              </Link>
            ))}
          </div>
        </Row>
      </Container>
    </ThrottleFooterStyles>
  );
};

export default Footer;

const getData = graphql`
  {
    data: datoCmsFooterComponent {
      internalName
      contact {
        heading
        subhead
        icon {
          internalName
          image {
            gatsbyImageData(
              imgixParams: {
                fm: "webp"
                auto: "compress"
                maxW: 1080
                fit: "clip"
                q: 35
              }
            )
            url
          }
        }
        contactOptions {
          id
          internalName
          heading
          subhead
          icon {
            image {
              gatsbyImageData(
                imgixParams: {
                  fm: "webp"
                  auto: "compress"
                  maxW: 1080
                  fit: "clip"
                  q: 35
                }
              )
              url
            }
          }
        }
      }
      footerMenu {
        menuItem {
          internalName
          childMenuItem {
            menuItemCallToAction {
              label
              url
            }
          }
        }
      }
      privacy {
        copywrite
        icon {
          internalName
          image {
            gatsbyImageData(
              imgixParams: {
                fm: "webp"
                auto: "compress"
                maxW: 1080
                fit: "clip"
                q: 35
              }
            )
            url
          }
        }
        links {
          label
          url
        }
      }
    }
  }
`;
