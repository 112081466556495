import styled from 'styled-components';
import arrow_pattern from '../../../images/Arrow_Pattern.png';

export default styled.section`
  background: rgba(0, 0, 0, 0.8);
  padding-top: 96px;
  z-index: 10;
  position: relative;

  &.static-footer {
    background-color: #080809 !important;
    padding-top: 96px !important;
  }

  &.arrow-bg {
    background-image: url(${arrow_pattern});
    background-repeat: no-repeat;
    width: 100%;
    background-position: top;
    background-size: 100%;
    padding-top: 150px;
    @media screen and (max-width: 768px) {
      background-size: auto;
    }
  }
  .footer-desc-area {
    p.short-title {
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 200%;
      text-align: center;
      letter-spacing: 2px;
      margin-bottom: 28px;
      color: #fc6a2c;
    }
    img {
      width: 266px;
      margin-bottom: 28px;
    }
    p.long-desc {
      margin: 0 auto;
      max-width: 750px;
      margin-bottom: 40px;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 140%;
      text-align: center;
      color: #ffffff;
    }
    p.short-desc {
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 23px;
      color: #ffffff;
      span {
        text-decoration: underline;
        color: #00cfe0;
      }
    }
  }
  .privacy-bar {
    max-width: 770px;
    margin: 0 auto;
    padding-top: 40px;
    padding-bottom: 40px;
    position: relative;
    color: #91949b;
    font-size: 15px;
    font-weight: 200;
    display: flex;
    align-items: center;

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }

    .copyright {
      display: flex;
      align-items: center;
      figure {
        margin-bottom: 0;
        margin-right: 16px;
      }
    }

    .privacy {
      display: flex;
      align-items: center;
      color: #fff;
      position: absolute;
      right: 0;
      @media (max-width: 768px) {
        position: static;
      }

      &-link {
        color: #fff;
        padding: 0 24px;
        border-right: 1px solid #2c3641;

        &:last-of-type {
          padding-right: 0;
          border-right: none;
        }
      }
    }
  }
`;
