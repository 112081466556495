import styled from 'styled-components';

export default styled.section`
  background: rgba(0, 0, 0, 1);
  @media screen and (max-width: 768px) {
    padding-top: 40px;
  }
  p {
    margin-bottom: 0px;
  }
`;
